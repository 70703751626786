import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDebounce } from 'use-debounce';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Radio,
  Stack,
  Typography,
} from '@mui/material';

import { useAddProfileMutation } from '../../services/apis/ProfileService';
import { addressColors } from './mockedData';
import CustomModal from '../../common/components/Model';
import DefaultAvatar from './DefaultAvatar';
import Input from '../../common/components/Input';
import InputTitle from '../../common/components/InputTitle';
import CountryCodeList from '../../common/components/CountryCodeList';
import Dropdown from '../../common/components/Dropdown';
import addNewAvatarIcon from '../../assets/Images/add-avatar.png';
import { checkFieldInput } from '../../utils';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './styles.module.scss';
import { findAddress } from '../../utils';
import Layout from '../../layouts/Layout/Layout';
import { useGetAccountDetails } from '../../common/hooks/useGetAccountDetails';
import { uploadProfileImage } from '../../common/utils/uploadProfileImage';
import { toast } from 'react-toastify';
import { useGetAllProfilesQuery } from '../../services/apis/ProfileService';
const initialValues = {
  FirstName: '',
  LastName: '',
  gender: '',
  country: { label: 'United Kingdom', value: '+44', flag: '🇬🇧' },
  address: '',
  emergencyContact: '',
  firstAid: '',
  dob: '',
  clientId: '',
  line_1: '',
  line_2: '',
  city: '',
  postcode: '',
  profileImage: undefined,
  skinTone: '',
  addressColor: '#4FC3F7',
  manual_country: '',
};

const genders = [
  { text: 'Male', value: 'Male' },
  { text: 'Female', value: 'Female' },
];

const AddChild = () => {
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [manualAddress, setManualAddress] = useState(false);
  const [open, setOpen] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [isGenderTouched, setIsGenderTouched] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [allChildNames, setAllChildNames] = useState([]);
  const [addProfile] = useAddProfileMutation();
  const { account } = useGetAccountDetails();
  const { data: allChildProfiles } = useGetAllProfilesQuery(
    account?.accountId,
    {
      skip: !Boolean(account?.accountId),
    }
  );
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const getValidationSchema = () => {
    let schema = Yup.object({
      profileImage: Yup.mixed().required('Profile Photo is required').t,
      FirstName: Yup.string()
        .required('Please Enter First Name')
        .min(2, 'should be atleast two digits'),
      LastName: Yup.string()
        .required('Please Enter Last Name')
        .min(2, 'Should be atleast two digits'),
      firstAid: Yup.string(),
      gender: Yup.string().required('Please Select Gender'),
      dob: Yup.string()
        .test(
          'dob-range',
          'Date of Birth must be between 2 and 60 years old.',
          function (value) {
            const today = new Date();
            const birthDate = new Date(this.resolve(Yup.ref('dob'), '', value));
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
              age--;
            }
            return age >= 2 && age <= 60;
          }
        )
        .required('Please Select a Date of Birth'),
      country: Yup.object().required('Please Select Country'),
      address: Yup.string().required('Please Select Address'),
      emergencyContact: Yup.string()
        .required('Please Enter Alternative Contact no.')
        .test(
          'is-10-digits',
          'Alternative Contact no. must be 10 digits',
          (value) => value && value.length === 10
        )
        .test('uk-starts-with-7', 'UK number must start with 7', (value) => {
          if (values.country.value === '+44') {
            const firstDigit = value.charAt(0);
            return firstDigit === '7';
          }
          return true;
        })
        .test(
          'not-unique-number',
          'Number is already registered',
          (value) => Number(value) !== Number(account.phoneNumber.slice(3))
        ),

      skinTone: Yup.string().default(''),
      addressColor: Yup.string().default(''),
    });

    if (manualAddress) {
      schema = schema.shape({
        address: Yup.string(),
        line_1: Yup.string()
          .required('Please add line one')
          .trim()
          .matches(
            /^[a-zA-Z0-9\s\-,.#]+$/,
            'Line one can only contain letters, numbers, spaces, hyphens, commas, periods, and pound signs'
          )
          .test(
            'no-html',
            'Line one contains invalid characters',
            (value) => !/<\/?[a-z][\s\S]*>/i.test(value)
          )
          .min(2, 'Line one must be at least 2 characters')
          .max(50, 'Line one must be at most 50 characters'),
        line_2: Yup.string()
          .trim()
          .matches(
            /^[a-zA-Z0-9\s\-,.#]+$/,
            'Line one can only contain letters, numbers, spaces, hyphens, commas, periods, and pound signs'
          )
          .test(
            'no-html',
            'Line one contains invalid characters',
            (value) => !/<\/?[a-z][\s\S]*>/i.test(value)
          )
          .min(2, 'Line one must be at least 2 characters')
          .max(50, 'Line one must be at most 50 characters'),
        city: Yup.string()
          .trim()
          .required('Please add town/city')
          .matches(
            /^[a-zA-Z0-9\s\-']+$/,
            'City can only contain letters, numbers, spaces, hyphens, and apostrophes'
          )
          .test(
            'no-html',
            'City contains invalid characters',
            (value) => !/<\/?[a-z][\s\S]*>/i.test(value)
          )
          .min(2, 'City must be at least 2 characters')
          .max(50, 'City must be at most 50 characters'),
        manual_country: Yup.string()
          .trim()
          .required('Please add country')
          .matches(
            /^[a-zA-Z0-9\s\-']+$/,
            'Country can only contain letters, numbers, spaces, hyphens, and apostrophes'
          )
          .test(
            'no-html',
            'City contains invalid characters',
            (value) => !/<\/?[a-z][\s\S]*>/i.test(value)
          )
          .min(2, 'Country must be at least 2 characters')
          .max(50, 'Country must be at most 50 characters'),
      });
    }

    return schema;
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: getValidationSchema(),
    enableReinitialize: true,
    onSubmit: async (values) => {
      const {
        profileImage,
        FirstName,
        LastName,
        address,
        line_1,
        firstAid,
        gender,
        dob,
        country,
        emergencyContact,
        postcode,
        manual_country,
      } = values;

      try {
        setError(false);
        let reqBody = {
          profileImage,
          profileFirstName: FirstName || '',
          profileLastName: LastName || '',
          dob,
          address: manualAddress ? `${line_1}, ${manual_country}` : address,
          postcode: postcode,
          firstAidInfo: firstAid,
          picture: '',
          gender: gender,
          alternateEmergencyNo: `${country.value}${emergencyContact}`,
          minutes: 0,
          accountId: account.accountId,
          profileCardColor: values.addressColor || addressColors[0]?.color,
        };
        if (isChildNameExist()) {
          toast.error(
            `You Already Have Profile For ${values.FirstName} ${values.LastName}`
          );
        } else if (!profileImage) {
          toast.error(`Please Select Proflie Image`);
        } else {
          setLoading(true);
          const response = await addProfile(reqBody);
          if (response.data) {
            await uploadProfileImage(profileImage, response.data.id);

            setOpen(true);
            setTimeout(() => {
              navigate('/dashboard');
            }, 1000);
          } else if (!response.ok) {
            setError(true);
            setOpen(true);
            throw new Error('Failed to submit form');
          }
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const isChildNameExist = () => {
    const childFullName =
      values.FirstName.toLowerCase() + values.LastName.toLowerCase();
    if (allChildNames.includes(childFullName)) {
      return true;
    }
    return false;
  };
  const onSelectDropdownOption = (value) => {
    if (value !== '') {
      const selectedAddressZipcode = addresses.find(
        (address) => address.value === value
      )?.zipcode;
      setFieldValue('postcode', selectedAddressZipcode);
      setFieldValue('address', value);
    }
  };

  const onClickManualAddress = () => {
    if (manualAddress) {
      setManualAddress(false);
      return;
    }
    setFieldValue('city', '');

    setManualAddress(true);
  };

  const [debouncedValue] = useDebounce(values.address, 500);

  useEffect(() => {
    if (debouncedValue) {
      findAddress(debouncedValue, setAddresses);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (values.profileImage && selectedAvatar !== 'G' && selectedAvatar !== 'B')
      setUploadedImage(URL.createObjectURL(values.profileImage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.profileImage]);

  useEffect(() => {
    if (allChildProfiles?.length > 0) {
      const fullNames = allChildProfiles.map((profile) => {
        const firstName = profile.profileFirstName.toLowerCase();
        const lastName = profile.profileLastName
          ? `${profile.profileLastName.toLowerCase()}`
          : '';
        return `${firstName}${lastName}`;
      });
      setAllChildNames(fullNames);
    }
  }, [allChildProfiles]);

  return (
    <Layout>
      <CustomModal open={open} setOpen={setOpen} error={error} />
      <Box className={styles.mainContainer} color="black.light">
        <h1 className={styles.h1}>Add Profile Details</h1>

        <form onSubmit={handleSubmit}>
          <div className={styles.details}>
            <Grid
              container
              spacing={matches ? 0 : 1.5}
              rowSpacing={matches ? 2 : 0}
            >
              <Grid item xs={12} md={6}>
                <InputTitle
                  label="Select a Photo or Avatar"
                  color="black.light"
                />
                <Box mt={1} display="flex" alignItems="center" gap={'10px'}>
                  <label htmlFor="profileImage">
                    {
                      <Box
                        component="input"
                        type="file"
                        id="profileImage"
                        accept="image/jpeg,image/jpg"
                        onChange={(event) => {
                          setSelectedAvatar('U');
                          const file = event.target.files[0];
                          if (file) {
                            setFieldValue('profileImage', file);
                          }
                        }}
                        width="24px"
                        height="24px"
                        display="none"
                      />
                    }
                    <div className="avatarUpload">
                      <Avatar
                        alt="add avatar"
                        src={addNewAvatarIcon}
                        sx={{
                          width: '48px',
                          height: '48px',
                          cursor: 'pointer',
                        }}
                      />
                    </div>
                  </label>
                  {uploadedImage && (
                    <Avatar
                      alt="uploaded avatar"
                      src={uploadedImage}
                      sx={{
                        width: '48px',
                        height: '48px',
                        border: `${selectedAvatar === 'U' && '2px solid'}`,
                        borderColor: `${
                          selectedAvatar === 'U'
                            ? 'primary.main'
                            : 'borderLight.main'
                        }`,
                        cursor: 'pointer',
                      }}
                      onClick={() => setSelectedAvatar('U')}
                    />
                  )}
                  {['B', 'G'].map((avatarType) => (
                    <DefaultAvatar
                      key={avatarType}
                      isSelected={selectedAvatar === avatarType}
                      avatarPathsList={Array.from(
                        { length: 7 },
                        (_, i) => `/AVATAR${i + 1}${avatarType}.svg`
                      )}
                      setValue={(file) => {
                        setFieldValue('profileImage', file);
                        setSelectedAvatar(avatarType);
                      }}
                    />
                  ))}
                </Box>
                {touched.profileImage && errors.profileImage && (
                  <Typography
                    sx={{
                      color: 'richRed.main',
                      fontWeight: '600',
                      fontSize: '12px',
                      marginTop: '1px',
                      // textTransform: "capitalize",
                    }}
                  >
                    {errors.profileImage}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <InputTitle
                  label="Set Background Color of Profile"
                  color="black.light"
                />
                <Grid item xs={12} mt={1} className={styles.addressContainer}>
                  {addressColors.map((i, index) => (
                    <Box
                      key={index}
                      className={styles.colors}
                      backgroundColor={i.color}
                      position="relative"
                      onClick={() => setFieldValue('addressColor', i.color)}
                    >
                      {values.addressColor === i.color && (
                        <img src="/check.png" alt="" />
                      )}
                    </Box>
                  ))}
                </Grid>
              </Grid>

              <Grid item md={3} xs={12}>
                <Input
                  label="First Name"
                  type="text"
                  name="FirstName"
                  isRequired
                  value={values.FirstName}
                  handleChangeEvent={(e) => {
                    checkFieldInput(e, setFieldValue, /^[a-zA-Z]+$/, 25);
                  }}
                  handleBlurEvent={handleBlur}
                  touched={touched.FirstName}
                  error={errors.FirstName}
                  noHeight
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <Input
                  label="Last Name"
                  type="text"
                  name="LastName"
                  isRequired
                  value={values.LastName}
                  handleChangeEvent={(e) => {
                    checkFieldInput(e, setFieldValue, /^[a-zA-Z]+$/, 25);
                  }}
                  handleBlurEvent={handleBlur}
                  touched={touched.LastName}
                  error={errors.LastName}
                  noHeight
                />
              </Grid>

              <Grid item md={6} xs={12} className="input_box">
                <InputTitle label="Gender" isRequired />
                <Box
                  display="flex"
                  alignItems="center"
                  mt={'4px'}
                  width="100%"
                  columnGap={2}
                >
                  {genders.map(({ text, value }) => {
                    const isActive = values.gender === value;
                    return (
                      <Box
                        key={value}
                        height="51px"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        borderRadius="8px"
                        padding="1px 10px 1px 20px"
                        border="1px solid"
                        borderColor={
                          isActive ? 'lightBlue.main' : 'borderLight.main'
                        }
                        width="100%"
                        onClick={() => {
                          setFieldValue('gender', value);
                          setIsGenderTouched(true);
                        }}
                        bgcolor={isActive ? 'lightBlue.main' : 'transparent'}
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        <Box
                          component="span"
                          fontWeight={400}
                          fontSize="15px"
                          color="inputColor"
                          sx={{
                            cursor: 'pointer',
                            color: isActive
                              ? 'textWhite.main'
                              : 'inputColor.main',
                          }}
                        >
                          {text}
                        </Box>
                        <Radio
                          checked={isActive}
                          sx={{
                            color: 'inputColor.main',
                            '&.Mui-checked': { color: 'textWhite.main' },
                          }}
                        />
                      </Box>
                    );
                  })}
                </Box>
                {errors.gender && (touched.gender || isGenderTouched) && (
                  <Typography
                    sx={{
                      color: 'richRed.main',
                      fontWeight: '600',
                      fontSize: '12px',
                      marginTop: '1px',
                      marginBottom: '10px',
                    }}
                  >
                    Please Select Gender
                  </Typography>
                )}
              </Grid>

              <Grid item md={6} xs={12}>
                <Input
                  label="First Aid Information"
                  placeholder="Allergies?"
                  type="text"
                  name="firstAid"
                  value={values.firstAid}
                  handleChangeEvent={(e) => {
                    checkFieldInput(e, setFieldValue);
                  }}
                  handleBlurEvent={handleBlur}
                  error={errors.firstAid}
                  touched={touched.firstAid}
                  noHeight
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Input
                  noHeight
                  label="Date of Birth"
                  name="dob"
                  type="date"
                  isRequired
                  value={values.dob}
                  error={errors.dob}
                  touched={touched.dob}
                  handleChangeEvent={handleChange}
                  handleBlurEvent={handleBlur}
                  max={new Date().toISOString().split('T')[0]}
                />
              </Grid>

              <Grid item xs={12}>
                <InputTitle label="Address Details" isRequired />
                <Dropdown
                  clear={manualAddress}
                  placeholder="Add address"
                  options={addresses}
                  setFieldValue={(value) => onSelectDropdownOption(value)}
                  error={errors.address}
                  touched={touched.address}
                  disabled={manualAddress}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  // color="black"
                  fontSize="13px"
                  // mb="10px"
                  sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    // marginX: isMobile ? "0px" : "0px",
                    color: 'black.main',
                    fontWeight: '500',
                  }}
                  onClick={onClickManualAddress}
                >
                  Enter Address Manually
                </Typography>
                {manualAddress && (
                  <>
                    <Grid mt="10px" item xs={12}>
                      <Input
                        placeholder="Address line 1"
                        type="text"
                        name="line_1"
                        value={values.line_1}
                        handleChangeEvent={handleChange}
                        handleBlurEvent={handleBlur}
                        error={errors.line_1}
                        touched={touched.line_1}
                        noHeight
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        placeholder="Address line 2"
                        type="text"
                        name="line_2"
                        value={values.line_2}
                        handleChangeEvent={handleChange}
                        handleBlurEvent={handleBlur}
                        error={errors.line_2}
                        touched={touched.line_2}
                        noHeight
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        placeholder="Town/city"
                        type="text"
                        name="city"
                        value={values.city}
                        handleChangeEvent={(e) => {
                          checkFieldInput(e, setFieldValue);
                        }}
                        handleBlurEvent={handleBlur}
                        error={errors.city}
                        touched={touched.city}
                        noHeight
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        placeholder="Postcode"
                        name="postcode"
                        value={values.postcode}
                        handleChangeEvent={(e) => {
                          let regex = /^[a-zA-Z0-9\s]+$/;
                          const value = e.target.value;
                          const name = e.target.name;
                          const cleanedValue = value.replace(/\s+/g, '');
                          if (!7 || cleanedValue.length <= 7) {
                            if (!value || regex.test(value)) {
                              setFieldValue(
                                name,
                                value
                                  ? value.charAt(0).toUpperCase() +
                                      value.slice(1)
                                  : ''
                              );
                            }
                          }
                        }}
                        handleBlurEvent={handleBlur}
                        error={errors.postcode}
                        touched={touched.postcode}
                        noHeight
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        placeholder="Country"
                        type="text"
                        name="manual_country"
                        value={values.manual_country}
                        handleChangeEvent={(e) => {
                          checkFieldInput(e, setFieldValue);
                        }}
                        handleBlurEvent={handleBlur}
                        error={errors.manual_country}
                        touched={touched.manual_country}
                        noHeight
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid xs={12} item>
                <InputTitle label="Add Alternative Contact No" isRequired />
                <Grid container>
                  <Grid item md={1.5} xs={3.5}>
                    <Box mt={'5px'}>
                      <CountryCodeList
                        error={errors.country}
                        label=""
                        showLabel={false}
                        disable={true}
                        onSelectChange={(selectedOption) => {
                          setFieldValue('country', selectedOption);
                        }}
                        countryCode={values.country}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={10.5}
                    xs={8.5}
                    sx={{ paddingLeft: '6px !important' }}
                  >
                    <Box
                      position={'relative'}
                      display="flex"
                      sx={{
                        paddingLeft: `${matches ? '6px !important' : ''}`,
                      }}
                    >
                      <Input
                        name="emergencyContact"
                        value={values.emergencyContact}
                        error={errors.emergencyContact}
                        touched={touched.emergencyContact}
                        handleBlurEvent={handleBlur}
                        handleChangeEvent={(e) =>
                          checkFieldInput(e, setFieldValue, /^\d+$/, 10)
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.btnContainer}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    sx={{
                      color: 'textWhite.main',
                      width: '100%',
                      height: '45px',
                      borderRadius: '50px',
                      textTransform: 'capitalize',
                    }}
                  >
                    {!isLoading ? (
                      'Add'
                    ) : (
                      <CircularProgress size={22} color="textWhite" />
                    )}
                  </Button>
                  <button
                    className={styles.btn}
                    onClick={() => navigate('/dashboard')}
                  >
                    <Stack direction="row" spacing={1} justifyContent="center">
                      <img src="/back.svg" alt="back"></img> <span>Back</span>{' '}
                    </Stack>
                  </button>
                </div>
              </Grid>
            </Grid>
          </div>
        </form>
      </Box>
    </Layout>
  );
};

export default AddChild;
